var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-data-table",
        {
          staticClass: "elevation-1 ma-3 mytable",
          attrs: {
            headers: _vm.headers,
            items: _vm.items,
            "options.multisort": "True",
            "items-per-page": _vm.items_per_page,
            "sort-by": _vm.sort_by,
            "sort-desc": _vm.sort_desc,
            dense: "",
            "hide-default-header": "",
            id: _vm.table_id,
            "footer-props": {
              "items-per-page-options": _vm.calc_items_per_page(
                _vm.items ? _vm.items.length : 0
              )
            }
          },
          on: {
            "update:sortBy": function($event) {
              _vm.sort_by = $event
            },
            "update:sort-by": function($event) {
              _vm.sort_by = $event
            }
          },
          scopedSlots: _vm._u(
            [
              {
                key: "header",
                fn: function(ref) {
                  var props = ref.props
                  return _vm._l(props.headers, function(head, i) {
                    return _c(
                      "th",
                      {
                        key: head.id,
                        class: {
                          table_narow: i < 4,
                          table_wide: i >= 4,
                          table_header_color_ix: i === 0,
                          "text-start": i === 1,
                          opportunities: head.text === "Opportunities",
                          opportunities: head.text === "Main-Region",
                          subregion: head.text === "Sub-Region",
                          headcol: i === 0
                        }
                      },
                      [
                        i === 0
                          ? _c(
                              "v-btn",
                              {
                                staticClass: "ml-3",
                                attrs: { "x-small": "", text: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.html_to_xls_download_dialog_1pmc_country(
                                      _vm.table_id,
                                      _vm.$store.state.tradeDSM
                                        .dsm_filter_setttings.Filters.META
                                        .selections.products.HS6_Code.length ===
                                        1
                                        ? "_" +
                                            _vm.$store.state.tradeDSM
                                              .dsm_filter_setttings.Filters.META
                                              .selections.products.HS6_Code[0]
                                        : ""
                                    )
                                  }
                                }
                              },
                              [
                                _c(
                                  "v-icon",
                                  { staticClass: "green--text mb-12 mr-2" },
                                  [_vm._v("mdi-microsoft-excel")]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        i === 0 && _vm.enable_market_click
                          ? _c(
                              "v-btn",
                              {
                                staticClass: "yellow ml-1 mb-1 ",
                                attrs: {
                                  "x-small": "",
                                  disabled: _vm.selected_items.length === 0
                                },
                                on: { click: _vm.apply_market_selections }
                              },
                              [
                                _vm._v(
                                  "Apply (" +
                                    _vm._s(_vm.selected_items.length) +
                                    ") "
                                )
                              ]
                            )
                          : _vm._e(),
                        i > 1
                          ? _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "span",
                                            _vm._g(
                                              {
                                                staticClass: "sort-cursor",
                                                class: {
                                                  "country-col": i === 1,
                                                  "reo-col":
                                                    i === 2 &&
                                                    head.text !==
                                                      "Opportunities"
                                                },
                                                domProps: {
                                                  innerHTML: _vm._s(head.text)
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.sort_clicked(
                                                      head.value
                                                    )
                                                  }
                                                }
                                              },
                                              on
                                            )
                                          ),
                                          _c(
                                            "span",
                                            [
                                              _vm.sort_by === head.value &&
                                              i !== 0
                                                ? _c(
                                                    "v-icon",
                                                    {
                                                      staticClass:
                                                        "text--accent-4"
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.sort_desc
                                                              ? "mdi-arrow-down"
                                                              : "mdi-arrow-up"
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    "Sort " +
                                      _vm._s(
                                        _vm.sort_desc &&
                                          _vm.sort_by === head.value
                                          ? "ascending"
                                          : "descending"
                                      ) +
                                      " "
                                  )
                                ])
                              ]
                            )
                          : [
                              head.text === "Country"
                                ? [
                                    _c("v-checkbox", {
                                      staticClass: "ml-4",
                                      attrs: { small: "" },
                                      on: {
                                        change: _vm.enable_disable_clicked
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "label",
                                            fn: function() {
                                              return [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "caption narrow"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          !_vm.enable_market_click
                                                            ? "Enable Selection Shortcuts"
                                                            : "Disable Selection Shortcuts"
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ],
                                        null,
                                        true
                                      ),
                                      model: {
                                        value: _vm.enable_market_click,
                                        callback: function($$v) {
                                          _vm.enable_market_click = $$v
                                        },
                                        expression: "enable_market_click"
                                      }
                                    })
                                  ]
                                : _vm._e(),
                              i !== 0
                                ? [
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: { top: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                var attrs = ref.attrs
                                                return [
                                                  _c(
                                                    "span",
                                                    _vm._g(
                                                      {
                                                        staticClass:
                                                          "sort-cursor",
                                                        class: {
                                                          "ml-4": i === 1
                                                        },
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            head.text
                                                          )
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.sort_clicked(
                                                              head.value
                                                            )
                                                          }
                                                        }
                                                      },
                                                      on
                                                    )
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            "Sort " +
                                              _vm._s(
                                                _vm.sort_desc &&
                                                  _vm.sort_by === head.value
                                                  ? "ascending"
                                                  : "desending"
                                              ) +
                                              " "
                                          )
                                        ])
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      [
                                        _vm.sort_by === head.value && i !== 0
                                          ? _c(
                                              "v-icon",
                                              { staticClass: "text--accent-4" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.sort_desc
                                                        ? "mdi-arrow-down"
                                                        : "mdi-arrow-up"
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ]
                                : _c("span", {
                                    staticClass: "sort-cursor",
                                    class: { "ml-4": i === 1 },
                                    domProps: { innerHTML: _vm._s(head.text) }
                                  })
                            ]
                      ],
                      2
                    )
                  })
                }
              },
              {
                key: "item.id_col",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex align-items-center justify-content-between pr-4"
                      },
                      [
                        _c("span", { staticClass: "ma-1" }, [
                          _vm._v(_vm._s(item["id_col"]))
                        ]),
                        _vm.enable_market_click
                          ? _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: item["checked"],
                                  expression: "item['checked']"
                                }
                              ],
                              staticClass: "ma-1",
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(item["checked"])
                                  ? _vm._i(item["checked"], null) > -1
                                  : item["checked"]
                              },
                              on: {
                                change: function($event) {
                                  var $$a = item["checked"],
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          item,
                                          "checked",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          item,
                                          "checked",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(item, "checked", $$c)
                                  }
                                }
                              }
                            })
                          : _vm._e()
                      ]
                    )
                  ]
                }
              },
              {
                key: "item.C_Name",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _vm.enable_market_click
                      ? _c(
                          "span",
                          {
                            staticClass: "text-left reo-table-selector",
                            on: {
                              click: function($event) {
                                return _vm.table_click(item["C_Name"])
                              }
                            }
                          },
                          [_vm._v(" " + _vm._s(item["C_Name"]) + " ")]
                        )
                      : _c(
                          "span",
                          { staticClass: "text-left reo-table-selector-off" },
                          [_vm._v(" " + _vm._s(item["C_Name"]) + " ")]
                        )
                  ]
                }
              },
              {
                key: "item.REO",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    item["REO_col"] >= 1 && item["REO_row"] >= 1
                      ? [
                          _c(
                            "v-tooltip",
                            {
                              staticClass: "green",
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "p",
                                          _vm._g(
                                            {
                                              staticClass: "reo-col",
                                              domProps: {
                                                innerHTML: _vm._s(item["REO"])
                                              }
                                            },
                                            on
                                          )
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            [
                              _c("span", [
                                _vm._v("REO"),
                                _c("sub", [
                                  _vm._v(
                                    _vm._s(item["REO_col"]) +
                                      "," +
                                      _vm._s(item["REO_row"])
                                  )
                                ]),
                                _c("br"),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.supply_text(
                                        item["REO_col"],
                                        item["C_Name"]
                                      )["tooltip_text"]
                                    ) +
                                    " "
                                ),
                                _c("br"),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.demand_text(
                                        item["REO_row"],
                                        item["C_Name"]
                                      )["tooltip_text"]
                                    ) +
                                    " "
                                )
                              ])
                            ]
                          )
                        ]
                      : _c("span", { staticClass: "reo-col ml-6" }, [
                          _vm._v("-")
                        ])
                  ]
                }
              },
              {
                key: "item.ad_valorem_tariff",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    Math.round(parseFloat(item.ad_valorem_tariff) * 100 * 10) /
                      10 >
                    0
                      ? _c(
                          "v-tooltip",
                          {
                            attrs: { left: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "div",
                                        _vm._g(
                                          { staticClass: "text-center" },
                                          on
                                        ),
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "no-link",
                                              attrs: {
                                                href: _vm.itc_url(item),
                                                target: "_blank"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    (
                                                      (parseFloat(
                                                        item.ad_valorem_tariff
                                                      ) *
                                                        100 *
                                                        100) /
                                                      100
                                                    ).toFixed(2)
                                                  )
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _c("span", { staticClass: "text-end" }, [
                              _vm._v("Click to view ITC tariff information")
                            ])
                          ]
                        )
                      : _c("span", { staticClass: "ml-2" }, [_vm._v(" - ")])
                  ]
                }
              },
              _vm.table_id === "reo_country"
                ? {
                    key: "item.num_col",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "opportunities",
                            class: { "red--text": item["Market_Rank"] === 0 }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(item["Market_Rank"]) +
                                "/" +
                                _vm._s(item["Market_REO_Count"]) +
                                " "
                            )
                          ]
                        )
                      ]
                    }
                  }
                : {
                    key: "item.opportunities",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("div", { staticClass: "opportunities" }, [
                          _vm._v(" " + _vm._s(item["opportunities"]) + " ")
                        ])
                      ]
                    }
                  },
              {
                key: "item.Avg6Major",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("div", { staticClass: "text-center" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.vFmt(
                              item["Avg6Major"] * _vm.value_convertion,
                              2
                            )
                          ) +
                          " "
                      )
                    ])
                  ]
                }
              },
              {
                key: "item.Halal_Adj_Potential",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("div", { staticClass: "text-center" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.vFmt(
                              item["Halal_Adj_Potential"] *
                                _vm.value_convertion,
                              2
                            )
                          ) +
                          " "
                      )
                    ])
                  ]
                }
              },
              {
                key: "item.HM_Exp_TM",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("div", { staticClass: "text-center" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            parseFloat(
                              _vm.vFmt(
                                item["HM_Exp_TM"] * _vm.value_convertion,
                                2
                              )
                            ) > 0
                              ? _vm.vFmt(
                                  item["HM_Exp_TM"] * _vm.value_convertion,
                                  2
                                )
                              : "-"
                          ) +
                          " "
                      )
                    ])
                  ]
                }
              },
              {
                key: "item.HM_TM_MShr",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("div", { staticClass: "text-center" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            item["HM_Exp_TM"] / item["TW_import_product"] > 0 &&
                              item["TW_import_product"] !== 0
                              ? Math.round(
                                  parseFloat(
                                    item["HM_Exp_TM"] /
                                      item["TW_import_product"]
                                  ) *
                                    100 *
                                    10
                                ) / 10
                              : "-"
                          ) +
                          " "
                      )
                    ])
                  ]
                }
              },
              {
                key: "item.row_d",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("div", { staticClass: "text-center" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            item["HM_Exp_TM"] / item["Avg6Major"] > 0 &&
                              item["Avg6Major"] !== 0
                              ? (
                                  (item["HM_Exp_TM"] / item["Avg6Major"]) *
                                  100
                                ).toFixed(1)
                              : "-"
                          ) +
                          " "
                      )
                    ])
                  ]
                }
              },
              {
                key: "item.Size6Major",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("div", { staticClass: "text-center" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            parseFloat(
                              _vm.vFmt(
                                item["Size6Major"] * _vm.value_convertion,
                                2
                              )
                            ) > 0
                              ? _vm.vFmt(
                                  item["Size6Major"] * _vm.value_convertion,
                                  2
                                )
                              : "-"
                          ) +
                          " "
                      )
                    ])
                  ]
                }
              },
              {
                key: "item.row_f",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("div", { staticClass: "text-center" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.vFmt(
                              (item["TW_import_product"] -
                                item["Size6Major"] -
                                item["HM_Exp_TM"]) *
                                _vm.value_convertion,
                              2
                            ) >= 0
                              ? _vm.vFmt(
                                  (item["TW_import_product"] -
                                    item["Size6Major"] -
                                    item["HM_Exp_TM"]) *
                                    _vm.value_convertion,
                                  2
                                )
                              : "-"
                          ) +
                          " "
                      )
                    ])
                  ]
                }
              },
              {
                key: "item.TW_import_product",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("div", { staticClass: "text-center" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.vFmt(
                              item["TW_import_product"] * _vm.value_convertion,
                              2
                            )
                          ) +
                          " "
                      )
                    ])
                  ]
                }
              }
            ],
            null,
            true
          )
        },
        [
          _vm._v(" //Number Column "),
          _vm._v(" //Country only Column "),
          _vm._v(" // REO Column "),
          _vm._v(" ` // Tariff "),
          _vm._v(" //opportunities or rank "),
          _vm._v(" //A "),
          _vm._v(" //A1 "),
          _vm._v(" //B "),
          _vm._v(" //C "),
          _vm._v(" //D "),
          _vm._v(" //E "),
          _vm._v(" //F "),
          _vm._v(" //G ")
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }